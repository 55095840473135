<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	// import Layout from "@/views/Layout";
	import { mapMutations } from "vuex";
	export default {
		name: "App",
		components: {
			// Layout
		},
		methods: {
			...mapMutations(['getNavList']),
		},
		mounted() {
			let list = this.$router.options.routes[1].children.filter(v => v.meta.tabs);
			this.getNavList(list);
		}
	}
</script>
<style lang="scss">
	// #app {
	// 	width: 1800px;
	// }
	@import url("styles/iconfont/iconfont.css");
</style>