<template>
	<!-- -->
	<div class="swiper-container" ref="currentSwiper" :style="{height:$props.height+'px'}">
		<div class="swiper-wrapper">
			<slot></slot>
		</div>
		<!-- 如果不需要以下功能，注释即可 -->
		<!-- 如果需要分页器 -->
		<div class="swiper-pagination" v-if="paginationShow"></div>
		<!-- 如果需要导航按钮 -->
		<div class="swiper-button-prev" v-if="navShow"></div>
		<div class="swiper-button-next" v-if="navShow"></div>
		<!-- 如果需要滚动条 -->
		<!-- <div class="swiper-scrollbar"></div> -->
	</div>
</template>
<script>
	//Swiper导入js
	import Swiper from "swiper";
	//Swiper引入css
	import "swiper/css/swiper.min.css";
	export default {
		name: 'Swiper',
		data() {
			return { swiper: null };
		},
		/**
		 * autoplay  @default false 是否自动播放
		 * direction @default horizontal 方向设置
		 *  - horizontal：横向切换
		 *  - vertical：竖向切换
		 */
		props: {
			list: {
				type: Array,
				default: () => [],
			},
			navShow: {
				type: Boolean,
				default: true,
			},
			paginationShow: {
				type: Boolean,
				default: true,
			},
			autoplay: {
				type: Boolean,
				default: true,
			},
			direction: {
				type: String,
				default: 'horizontal',
			},
			// 切换速度
			speed: {
				type: Number,
				default: 400,
			},
			height: {
				type: Number,
				default: 400,
			},
			slidesPerView: {
				type: Number,
				default: 1,
			},
			delay: {
				type: Number,
				default: 2000,
			},
			// 匀速滚动
			uniformSpeed: {
				type: Boolean,
				default: false,
			},
			spaceBetween: {
				type: Number,
				default: 20
			},
			reverseDirection: {
				type: Boolean,
				default: false
			}
		},
		mounted() {},
		computed: {},
		methods: {
			changeSwiper() {
				// 存放鼠标悬浮时的transform属性（行内属性）
				let nextTransForm = "";
				// 轮播图从暂停位置移动到原本应到的位置所用的时间
				let nextTime = 0;
				// 鼠标悬浮暂停轮播
				this.swiper.el.onmouseenter = function() {
					try {
						nextTransForm = document.getElementsByClassName("swiper-container")[0].getElementsByClassName(
							"swiper-wrapper")[0].style.transform;
						// 轮播图原本应移动到的位置
						let nextTransPosition = -1 * parseInt(document.getElementsByClassName("swiper-container")[0]
							.getElementsByClassName("swiper-wrapper")[0].style.transform.split("translate3d(")[1].split("px")[0]);
						// 鼠标悬浮时时轮播图位置
						let nowTransPosition = -1 * parseInt(window.getComputedStyle(document.getElementsByClassName(
							"swiper-container")[0].getElementsByClassName("swiper-wrapper")[0], false)["transform"].split("1, ")[
							2].split(",")[0]);
						// 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
						let nowTransForm = window.getComputedStyle(document.getElementsByClassName("swiper-container")[0]
							.getElementsByClassName("swiper-wrapper")[0], false)["transform"];
						// 计算轮播图从暂停位置移动到原本应到的位置所用的时间（370是我自定义的每个slide的宽度）
						nextTime = 5500 * ((nextTransPosition - nowTransPosition) / 370);
						// 改变行内transform属性
						document.getElementsByClassName("swiper-container")[0].getElementsByClassName("swiper-wrapper")[0].style
							.transform = nowTransForm;
						// 不写也没关系
						document.getElementsByClassName("swiper-container")[0].getElementsByClassName("swiper-wrapper")[0].style
							.transitionDuration = "0ms";
						this.swiper.autoplay.stop();
					} catch (error) {
						// console.error("异常处理：", error);
					}
				};
				// 鼠标离开轮播图开始轮播
				this.swiper.el.onmouseleave = function() {
					// console.log('leave' + nextTransForm)
					// 恢复原样
					document.getElementsByClassName("swiper-container")[0].getElementsByClassName("swiper-wrapper")[0].style
						.transform = nextTransForm;
					document.getElementsByClassName("swiper-container")[0].getElementsByClassName("swiper-wrapper")[0].style
						.transitionDuration = nextTime + "ms";
					this.swiper.autoplay.start();
				};
			}
		},
		watch: {
			list: {
				immediate: true,
				deep: true,
				handler(newVal, oldVal) {
					this.$nextTick(() => {
						let that = this;
						let swiperOptions = {
							loop: true, // 循环模式选项
							speed: this.$props.speed,
							spaceBetween: this.$props.spaceBetween,
							autoplay: {
								delay: this.$props.delay,
								stopOnLastSlide: this.$props.autoplay,
								reverseDirection: this.$props.reverseDirection,
								stopOnLastSlide: false, // 循环播放
								disableOnInteraction: false, // 用户操作后轮播仍然继续
							},
							direction: this.$props.direction,
							height: this.$props.height,
							slidesPerView: this.$props.slidesPerView,
							// 如果需要分页器
							pagination: {
								el: ".swiper-pagination",
							},
							// 如果需要前进后退按钮
							navigation: {
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							},
						};
						if (this.$props.uniformSpeed) {
							swiperOptions.autoplay.delay = 0;
							swiperOptions.speed = 3000;
							swiperOptions.freeMode = true;
							this.swiper = new Swiper(this.$refs.currentSwiper, swiperOptions);
							this.changeSwiper();
						} else {
							this.swiper = new Swiper(this.$refs.currentSwiper, swiperOptions);
						}
					});
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.swiper-container {
		width: 100%;

		// height: 400px;
		.swiper-slide {
			@include flexCenter;
		}
	}
</style>