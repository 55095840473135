<template>
	<div class="ArticleList-container">
		<div class="list">
			<el-empty v-if="!list.length" description="暂无内容"></el-empty>
			<div v-else>
				<div class="list-item" v-for="(item,index) in list" :key="index">
					<div class="time">
						<div class="day">
							{{$dateFun.formatDate(item.createTime,'DD')}}
						</div>
						<div class="year-month">
							{{$dateFun.formatDate(item.createTime,'YYYY-MM')}}
						</div>
					</div>
					<router-link :to="`${$route.fullPath}/detail/${item.sysArticleId}`"
						class="title">{{item.sysArticleTitle}}</router-link>
				</div>
			</div>
			<pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
				@pagination="getList" />
		</div>
	</div>
</template>
<script>
	import { listArticle } from "@/api/home"
	export default {
		name: 'ArticleList',
		props: ['categoryId', 'keywords'],
		data() {
			return {
				queryParams: {
					pageNum: 1,
					pageSize: 10,
				},
				list: [],
				total: 0
			};
		},
		mounted() {
			this.getList();
		},
		computed: {},
		methods: {
			getList() {
				if (this.$route.meta.column) this.queryParams.sysColumn = this.$route.meta.column;
				this.queryParams.sysCategoryId = this.$props.categoryId;
				this.queryParams.sysArticleTitle = this.$props.keywords;
				listArticle(this.queryParams).then(res => {
					this.list = res.rows;
					this.total = res.total;
				})
			}
		},
		watch: {
			categoryId: {
				handler(newVal, oldVal) {
					this.queryParams.sysCategoryId = newVal;
					this.total = 0;
					this.getList();
				}
			}
		}
	};
</script>
<style lang="scss">
	.el-breadcrumb {
		padding-top: 10px;
	}

	.ArticleList-container {
		width: 100%;

		.list {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.list-item {
				display: flex;
				// align-items: center;
				padding: 10px;
				border-bottom: 1px dotted #666;
				gap: 20px;

				.time {
					width: 80px;
					height: 80px;
					color: #fff;
					background: $primary;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					.day {
						font-size: 30px;
						line-height: 50px;
					}

					.year-month {
						justify-items: flex-end;
					}
				}

				.title:hover {
					color: $primary;
				}
			}

			.pagination-container {
				position: relative;
				bottom: 0;
			}
		}
	}
</style>