<template>
	<div class="footer-container">
		<div class="top-container">
			<div class="top container">
				<div class="site-nav">
					<div class="title">—— 网站导航 ——</div>
					<div class="nav-wrap">
						<div class="nav-item" v-for="item in navList" :key="item.path">
							<router-link :to="item.path">{{item.meta.title}}</router-link>
						</div>
					</div>
				</div>
				<div class="about">
					<div class="title">———— 联系我们 ————</div>
					<!-- {{contactUs}} -->
					<ul class="about-wrap">
						<li>联系人：{{contactUs.contanctPerson}}</li>
						<li>电话： {{contactUs.contanctPhone}}</li>
						<li>邮箱：{{contactUs.contanctEmail}}</li>
						<li>邮编：{{contactUs.postalCode}}</li>
						<li>地址：{{contactUs.contanctAddress}}</li>
					</ul>
				</div>
				<div class="follow">
					<div class="title">———— 关于我们 ————</div>
					<div class="follow-content">
						<div class="follow-code">
							<!-- baseUrl+ -->
							<img :src="contactUs.officialAccount" />
						</div>
						<div>
							<div class="follow-text">扫描二维码</div>
							<div class="follow-text">关注公众号</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
		</div>
		<div class="bottom-container">
			<div class="bottom container">
				<div class="ex">主办单位：河北省特色食品产业和文化研究会 </div>
				<!-- <div>技术支持：佰业通</div> -->
				<div>河北省特色食品产业和文化研究会版权所有，未经书面授权禁止使用 </div>
				<div class="copy-right">
					<!-- <a href="javascript:;">备案号</a> -->
					<a href="https://beian.miit.gov.cn" target="_blank">冀ICP备2023034200号-1</a>
					<a href="https://beian.mps.gov.cn" target="_blank">冀公网安备13010402002597号</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default {
		/* eslint-disable */
		name: "Footer",
		data() {
			return {
				content: null,
			};
		},
		computed: {
			...mapState(['navList', 'contactUs']),
		},
		mounted() {
			this.$store.dispatch('getConactUs');
		},
		methods: {}
	}
</script>
<style lang="scss">
	.footer-container {
		margin-top: 20px;

		// background: #ccc;
		.top-container {
			background-color: #ececee;

			.top {
				display: flex;
				justify-content: space-between;
				padding: 20px 0;

				.site-nav {
					.nav-wrap {
						display: grid;
						grid-template-columns: repeat(4, 1fr);
						grid-auto-columns: repeat(4, 1fr);
						grid-gap: 10px;

						.nav-item {
							color: #5a5a5a;
							font-size: 12px;
							padding: 5px;
							justify-self: center;
							align-self: center;

							span {
								cursor: pointer;

								&:hover {
									color: $primary;
								}
							}
						}
					}
				}

				.site-nav,
				.about,
				.follow {
					.title {
						margin-bottom: 10px;
						font-size: 14px;
						text-align: center;
						padding: 10px 0;
						vertical-align: middle;
					}
				}

				.about {
					.about-wrap {
						font-size: 12px;
						display: flex;
						align-items: flex-start;
						justify-content: center;
						flex-direction: column;
						color: #5a5a5a;
						gap: 15px;
					}
				}

				.follow {
					.follow-content {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.follow-code {
							width: 80px;
							height: 80px;

							img {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}

		.bottom-container {
			width: 100%;
			background: #ccc;

			.bottom {
				padding: 30px 0;
				color: #666;
				display: flex;
				flex-direction: column;
				gap: 15px;
				text-align: center;
				font-size: 12px;

				.copy-right {
					display: flex;
					gap: 10px;
					justify-content: center;

					a {
						color: #258bce;
					}
				}

				.ex {
					display: flex;
					align-items: center;
					justify-content: center;

					.cut {
						width: 20px;
					}
				}
			}
		}
	}
</style>