import {
	Button,
	Backtop,
	Menu,
	MenuItem,
	Carousel,
	CarouselItem,
	Message,
	Image,
	Icon,
	Input,
	Link,
	Card,
	Radio,
	RadioGroup,
	Tabs,
	TabPane,
	Form,
	FormItem,
	RadioButton,
	Breadcrumb,
	BreadcrumbItem,
	Pagination,
	Empty,
	Dialog,
	Submenu,
} from 'element-ui/lib';
import "@/styles/element-variables.scss";
export default {
	install: (Vue) => {
		Vue.use(Breadcrumb);
		Vue.use(Tabs);
		Vue.use(Submenu);
		Vue.use(Dialog);
		Vue.use(TabPane);
		Vue.use(BreadcrumbItem);
		Vue.use(Button);
		Vue.use(Backtop);
		Vue.use(Menu);
		Vue.use(Link);
		Vue.use(MenuItem);
		Vue.use(Carousel);
		Vue.use(CarouselItem);
		Vue.use(Image);
		Vue.use(Icon);
		Vue.use(Input);
		Vue.use(Card);
		Vue.use(Radio);
		Vue.use(RadioGroup);
		Vue.use(RadioButton);
		Vue.use(Form);
		Vue.use(FormItem);
		Vue.use(Pagination);
		Vue.use(Empty);
		Vue.prototype.$message = Message;
	}
}