import request from "@/utils/request";
// 查询轮播图列表
export function listSwiper(query) {
	return request({
		url: '/pc/swiper/list',
		method: 'get',
		params: query
	})
}
// 查询分类列表
export function listCategory(query) {
	return request({
		url: '/pc/category/list',
		method: 'get',
		params: query
	})
}
// 查询文章列表
export function listArticle(query) {
	return request({
		url: 'pc/article/list',
		method: 'get',
		params: query
	})
}
// 查询联系我们信息
export function listContactUs(query) {
	return request({
		url: 'pc/contactUs/list',
		method: 'get',
		params: query
	})
}
// 提交联系我们
export function submitAbout(data) {
	return request({
		url: '/pc/about',
		method: 'post',
		data,
	})
}
// 下载文件列表
export function listDownload(query) {
	return request({
		url: '/pc/downFiles/list',
		method: 'get',
		params: query
	})
}
// 文章详情
export function detailArticle(query) {
	return request({
		url: `/pc/article/${query}`,
		method: 'get',
	})
}
/* 合作单位列表 */
export function listCollaboration(query) {
	return request({
		url: `/pc/collaboration/list`,
		method: 'get',
		params: query
	})
}
export function listIshome(query) {
	return request({
		url: `/pc/category/ishome`,
		method: 'get',
		params: query
	})
}