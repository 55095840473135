<template>
	<div class="tabs-container">
		<div class="container">
			<el-menu :default-active="navList[0].path" mode="horizontal" router text-color="#000"
				background-color="transparent">
				<el-menu-item :style="{ width: 100 / navList.length + '%' }" :index="item.path" v-for="(item) in navList"
					:key="item.path">
					{{item.meta.title}}
				</el-menu-item>
			</el-menu>
		</div>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	export default {
		/* eslint-disable */
		name: "Tabs",
		data() {
			return {
				activeIndex: null,
				tabList: []
			};
		},
		computed: {
			...mapState(['navList']),
			// tabsList() {
			// 	let list = this.$router.options.routes[1].children.filter(item => item.meta.tabs);
			// 	return list;
			// }
		},
		mounted() {},
		methods: {}
	}
</script>
<style lang="scss">
	.tabs-container {
		background-color: $primary;

		.el-menu {
			border-bottom: none !important;

			.el-menu-item {
				height: 50px;
				color: #fff !important;
				background-color: $primary !important;
				@include flexCenter;
				border-bottom: none !important;
				border-right: 1px dotted #fff;

				&:last-child {
					border-right: none;
				}
			}

			.el-submenu .is-active {
				background-color: $primary !important;
				border-bottom: none !important;
			}

			.el-menu-item:hover {
				background-color: $primary !important;
				font-size: 16px !important;
			}
		}
	}
</style>