import Vue from 'vue'
import Vuex from 'vuex';
import { listContactUs, submitAbout } from "@/api/home"
Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		navList: [],
		contactUs: {},
		// 类型
		typeOptions: [
			//
			{
				value: 0,
				label: '理事单位',
			}, {
				value: 1,
				label: '特色产品',
			}, {
				value: 2,
				label: '友情链接',
			}
		],
	},
	getters: {},
	mutations: {
		getNavList(state, context) {
			state.navList = context;
		},
		setConactUs(state, context) {
			state.contactUs = context;
		}
	},
	actions: {
		getConactUs(state, value) {
			listContactUs().then(res => {
				state.commit('setConactUs', res.rows[0]);
				// this.contactUs = res.rows[0];
				// this.$store.commit('setConactUs', res.rows[0]);
			});
		}
	},
	modules: {}
})