<template>
	<div class="layout">
		<!-- 返回顶部 -->
		<el-backtop />
		<Header />
		<Tabs />
		<div class="container">
			<Breadcrumb />
			<router-view />
		</div>
		<Footer />
	</div>
</template>
<script>
	import Breadcrumb from "@/components/Breadcrumb";
	import Header from "./Header";
	import Tabs from "./Tabs";
	import Footer from "./Footer";
	export default {
		/* eslint-disable */
		name: "Layout",
		components: {
			Breadcrumb,
			Header,
			Tabs,
			Footer
		},
	}
</script>
<style lang="scss" scoped>
	.layout {
		width: 100%;
	}
</style>