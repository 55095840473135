<template>
	<div class="breadcrumb-container">
		<el-breadcrumb separator-class="el-icon-arrow-right" v-if="$route.fullPath!='/home'">
			<el-breadcrumb-item :to="item.path" v-for="(item, index) in breadcrumbList"
				:key="index">{{ item.meta.title }}</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>
<script>
	export default {
		name: 'Breadcrumb',
		data() {
			return {
				breadcrumbList: []
			};
		},
		mounted() {
			this.generateBreadcrumb();
		},
		computed: {},
		methods: {
			generateBreadcrumb() {
				// 获取当前路由信息
				const matched = this.$route.matched
				// 初始化面包屑导航数据
				const breadcrumbList = [];
				// 遍历路由信息，生成面包屑导航数据
				matched.forEach((item, index) => {
					let { meta, name, path } = item;
					if (path == '') path = '/';
					if (path == '/home') return;
					breadcrumbList.push({
						name,
						meta,
						path
					})
				})
				// console.log(breadcrumbList);
				// 保存面包屑导航数据
				this.breadcrumbList = breadcrumbList
			}
		},
		watch: {
			$route() {
				this.$nextTick(() => {
					this.generateBreadcrumb();
				});
			}
		}
	};
</script>
<style lang="scss" scoped>
	.el-breadcrumb {
		padding: 20px 0;
	}
</style>