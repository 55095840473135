<template>
	<div class="header-container">
		<div class="container header-content">
			<div class="left">
				<div class="logo">
					<img src="@/assets/images/logo.png" />
				</div>
				<div class="text">
					<div class="title">河北省特色食品产业和文化研究会</div>
				</div>
			</div>
			<div class="right">
				<div class="text-wrap">
					<span>弘扬</span>
					<span>传​承</span>
					<span>创新</span>
					<span>发展</span>
				</div>
				<div class="search-wrap">
					<el-input placeholder="请输入内容" size="small" v-model="searchParams.sysArticleTitle" clearable />
					<el-button type="primary" size="small" @click="openDialog">搜索</el-button>
				</div>
			</div>
		</div>
		<el-dialog title="搜索文章" :visible.sync="dialogVisible" width="40%">
			<div class="list">
				<el-empty v-if="!articleList.length" description="暂无内容"></el-empty>
				<div v-else>
					<div class="list-item" v-for="(item,index) in articleList" :key="index">
						<div class="time">
							<div class="day">
								{{$dateFun.formatDate(item.createTime,'DD')}}
							</div>
							<div class="year-month">
								{{$dateFun.formatDate(item.createTime,'YYYY-MM')}}
							</div>
						</div>
						<div class="title" @click="goDetail(item.sysArticleId)">
							{{item.sysArticleTitle}}
						</div>
					</div>
				</div>
				<pagination v-show="total>0" :total="total" :page.sync="searchParams.pageNum"
					:limit.sync="searchParams.pageSize" @pagination="getList" />
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
				<!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import { listArticle } from '@/api/home';
	import ArticleList from '../../compontents/ArticleList'
	export default {
		/* eslint-disable */
		name: "Header",
		components: {
			ArticleList
		},
		data() {
			return {
				dialogVisible: false,
				searchParams: {
					pageNum: 1,
					pageSize: 10,
				},
				articleList: [],
				total: 0
			}
		},
		methods: {
			openDialog() {
				this.dialogVisible = true;
				this.getList();
			},
			getList() {
				listArticle(this.searchParams).then(res => {
					this.articleList = res.rows;
					this.total = res.total;
				})
			},
			goDetail(id) {
				this.dialogVisible = false;
				this.$router.push(`/detail/${id}`);
			}
		}
	}
</script>
<style lang="scss">
	.header-container {
		padding: 20px 0;

		.header-content {
			display: flex;
			// align-items: center;
			justify-content: space-between;

			.left {
				display: flex;

				// align-items: flex-end;
				.logo {
					height: 100px;

					img {
						height: 100%;
					}
				}

				.text {
					margin-left: 10px;
					display: flex;
					align-items: center;

					// flex-direction: column;
					// gap: 10px;
					// justify-content: center;
					.title {
						cursor: pointer;
						font-size: 28px;
						color: $dark-red;
						text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
					}

					.title-English {
						color: #ccc;
						font-size: 12px;
						vertical-align: bottom;
					}

					.description {
						font-size: 14px;
						color: $dark-red;
					}
				}
			}

			.right {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 20px;

				.text-wrap {
					font-size: 26px;
					color: $dark-red;
					display: flex;
					gap: 20px;

					span {
						font-weight: bold;
					}
				}

				.search-wrap {
					align-self: flex-end;
					display: flex;
					gap: 1px;

					.el-input__inner,
					.el-button {
						border-radius: 0;
					}
				}
			}
		}

		.el-dialog {
			.list {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.list-item {
					cursor: pointer;
					display: flex;
					// align-items: center;
					padding: 10px;
					border-bottom: 1px dotted #666;
					gap: 20px;

					.time {
						width: 80px;
						height: 80px;
						color: #fff;
						background: $primary;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;

						.day {
							font-size: 30px;
							line-height: 50px;
						}

						.year-month {
							justify-items: flex-end;
						}
					}

					.title:hover {
						color: $primary;
					}
				}

				.pagination-container {
					margin-top: 20px;
					position: relative;
					bottom: 0;
					@include flexCenter;
				}
			}
		}
	}
</style>