import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from './utils/element';
// 分页组件
import Pagination from "@/components/Pagination";
import { baseUrl } from "@/utils/request";
Vue.use(element);
/* eslint-disable vue/multi-word-component-names */
Vue.component('Pagination', Pagination);
import Swiper from "@/components/Swiper";
Vue.component('Swiper', Swiper);
// 清除默认样式
import "@/styles/css/reset.css";
//引入富文本
import quillEditor from 'vue-quill-editor';
// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css';
Vue.use(quillEditor, /* { 默认全局 } */ )
import dateFun from "@/utils/date";
Vue.prototype.$dateFun = dateFun;
Vue.prototype.$baseUrl = baseUrl;
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')