export default {
	/**
	 * 根据指定的日期格式，将 Date 对象、字符串类型的日期或时间戳格式化为字符串。
	 *
	 * @param {Date | string | number} date - 要格式化的日期对象、字符串类型的日期或时间戳。
	 * @param {string} [format='YYYY-MM-DD hh:mm:ss'] - 日期格式字符串，其中可包含以下占位符：
	 * - YYYY：四位数年份
	 * - YY: 两位数年份
	 * - M：一位数或两位数月份（1 到 12）
	 * - MM：两位数月份（01 到 12）
	 * - D：一位数或两位数日期（1 到 31）
	 * - DD：两位数日期（01 到 31）
	 * - H：一位数或两位数小时（0 到 23）
	 * - HH：两位数小时（00 到 23）
	 * - h: 一位数或两位数小时（1 到 12）
	 * - hh: 两位数小时（01 到 12）
	 * - m：一位数或两位数分钟（0 到 59）
	 * - mm：两位数分钟（00 到 59）
	 * - s：一位数或两位数秒数（0 到 59）
	 * - ss：两位数秒数（00 到 59）
	 * @returns {string} 格式化后的日期字符串。
	 */
	formatDate(date, format = 'YYYY-MM-DD hh:mm:ss') {
		// 如果传入的日期是一个数字，则认为它是一个时间戳，将其转换为日期对象
		if (typeof date === 'number') {
			date = new Date(date)
		}
		// 将字符串类型的日期转换为日期对象
		if (typeof date === 'string') {
			date = new Date(date.replace(/-/g, '/'))
		}
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const hour = date.getHours();
		const minute = date.getMinutes();
		const second = date.getSeconds();
		// 定义映射关系，方便处理单一字符和两个字符的情况
		const rules = {
			Y: year,
			YY: String(year).slice(2),
			YYYY: year,
			M: month,
			MM: String(month).padStart(2, '0'),
			D: day,
			DD: String(day).padStart(2, '0'),
			H: hour,
			HH: String(hour).padStart(2, '0'),
			h: hour % 12 || 12,
			hh: String(hour % 12 || 12).padStart(2, '0'),
			m: minute,
			mm: String(minute).padStart(2, '0'),
			s: second,
			ss: String(second).padStart(2, '0'),
		};
		// 根据规则替换占位符
		return format.replace(/Y{1,4}|M{1,2}|D{1,2}|H{1,2}|h{1,2}|m{1,2}|s{1,2}/g, (match) => {
			return rules[match];
		});
	},
	/**
	 * 获取指定日期天数之后的详细信息。
	 * @param {Date|string|number} [date=new Date()] 指定的日期，可以是 Date 对象、时间戳或 ISO 8601 格式日期字符串，默认为当前日期。
	 * @param {number} [addDayCount=0] - 可选，需要添加或减去的天数，默认为 0 ，代表不添加或减去任何天数。
	 * @returns {Object} 包含详细信息的对象：
	 *   - `fullDate`：一个 "YYYY-MM-DD"格式的字符串，表示完整的日期。
	 *   - `timestamp`：一个时间戳，表示指定日期加上指定天数后的时间点。
	 *   - `year`：封装了日期对象中的年份。
	 *   - `month`：封装了日期对象中的月份，取值为 "01"~"12"。
	 *   - `date`：封装了日期对象中的日期，取值为 "01"~"31"。
	 *   - `day`：表示星期几，取值为 0~6，其中0表示星期日，1表示星期一，以此类推。
	 */
	getDate(date = new Date(), addDayCount = 0) {
		const dateObj = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
		const targetTimestamp = dateObj.getTime() + addDayCount * 24 * 60 * 60 * 1000;
		const targetDate = new Date(targetTimestamp);
		const year = targetDate.getFullYear();
		const month = `${(targetDate.getMonth() + 1)}`.padStart(2, '0');
		const day = `${targetDate.getDate()}`.padStart(2, '0');
		const weekDay = targetDate.getDay();
		return {
			fullDate: `${year}-${month}-${day}`,
			timestamp: targetTimestamp,
			year,
			month,
			date: day,
			day: weekDay
		};
	},
	/**
	 * 计算两个日期之间的天数
	 * @param {string} startDate - 起始日期，格式为“YYYY-MM-DD”
	 * @param {string} endDate - 结束日期，格式为“YYYY-MM-DD”
	 * @returns {number} 返回两个日期之间的天数，若参数不合法则返回 undefined
	 */
	daysBetween(startDate, endDate) {
		if (!(startDate && endDate)) return;
		const ONE_DAY = 1000 * 60 * 60 * 24; // 一天的毫秒数
		const [date1, date2] = [new Date(startDate), new Date(endDate)].map(date => date.getTime());
		const days = Math.ceil(Math.abs(date2 - date1) / ONE_DAY);
		return days;
	}
}