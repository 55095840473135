import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout'
Vue.use(VueRouter)
const routes = [
	// 
	{
		path: '/',
		redirect: 'home'
	}, {
		path: '/',
		name: 'layout',
		component: Layout,
		meta: {
			title: '网站首页',
			tabs: true,
		},
		children: [{
			path: '/home',
			name: 'home',
			component: () => import('@/views/Home'),
			meta: {
				title: '网站首页',
				tabs: true,
				column: 0
			}
		}, {
			path: '/about',
			name: 'about',
			component: () => import('@/views/About'),
			meta: {
				title: '关于我们',
				tabs: true,
				column: 1
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
					column: 1
				},
			}]
		}, {
			path: '/selfAssociationDynamic',
			name: 'selfAssociationDynamic',
			component: () => import('@/views/SelfAssociationDynamic'),
			meta: {
				title: '本会动态',
				tabs: true,
				column: 2
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
					column: 2
				},
			}]
		}, {
			path: '/memberClub',
			component: () => import('@/views/MemberClub'),
			meta: {
				title: '会员单位',
				tabs: true,
				column: 3
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
					column: 3
				},
			}]
		}, {
			path: '/branchInstitution',
			name: 'branchInstitution',
			component: () => import('@/views/BranchInstitution'),
			meta: {
				title: '分支机构',
				tabs: true,
				column: 4
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
					column: 4
				},
			}]
		}, {
			path: '/groupStandards',
			name: 'groupStandards',
			component: () => import('@/views/GroupStandards'),
			meta: {
				title: '团体标准',
				tabs: true,
				column: 5
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
					column: 5
				},
			}]
		}, {
			path: '/academicExchange',
			name: 'academicExchange',
			component: () => import('@/views/AcademicExchange'),
			meta: {
				title: '学术交流',
				tabs: true,
				column: 6
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
					column: 6
				},
			}]
		}, {
			path: '/events',
			name: 'events',
			component: () => import('@/views/Events'),
			meta: {
				title: '会展活动',
				tabs: true,
				column: 7
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
					column: 7
				},
			}]
		}, {
			path: '/downloadZone',
			name: 'downloadZone',
			component: () => import('@/views/Download'),
			meta: {
				title: '下载专区',
				tabs: true,
				column: 8
			}
		}, {
			path: '/contactUs',
			name: 'contactUs',
			component: () => import('@/views/ConactUs'),
			meta: {
				title: '联系我们',
				tabs: true,
				column: 9
			}
		}, {
			path: '/list/:categoryId',
			name: 'list',
			component: () => import('@/views/List'),
			meta: {
				title: '文章列表',
				tabs: false,
			},
			children: [{
				path: 'detail/:articleId',
				component: () => import('@/views/compontents/Detail'),
				meta: {
					title: '详情',
				},
			}]
		}, {
			path: 'detail/:articleId',
			name: 'detail',
			component: () => import('@/views/compontents/Detail'),
			meta: {
				title: '文章详情',
				tabs: false,
			},
		}, {
			path: '*',
			name: 'NotFound',
			component: () => import('@/views/NotFound'),
			meta: {
				tabs: false,
			}
		}]
	}
]
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: () => ({ y: 0 }),
})
// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
	return routerReplace.call(this, location).catch(err => err)
}
export default router