// 对于axios进行二次封装
import axios from "axios";
// 在当前模块中引入store
import store from '@/store';
import { Message } from 'element-ui';
// 开发环境
// export const baseUrl = 'http://192.168.0.197:8080';
// 线上环境
export const baseUrl = 'http://1.13.2.124:8080';
// 1.利用axios对象的方法create，去创建一个axios实例
// 2.request就是axios，只不过稍微配置一下
const requests = axios.create({
	baseURL: baseUrl, //请求基本路径
	// baseURL: 'http://122.114.173.213:8080/ruoyi-admin/',
	// baseURL: "http://localhost:8081",
	timeout: 5000 //超时时间
})
//请求拦截器
requests.interceptors.request.use((config) => {
	return config;
})
// 响应拦截器
requests.interceptors.response.use(
	(res) => { //响应成功的回调
		return res.data;
	},
	//响应失败的回调
	(error) => {
		return Message.error(error.message)
		// return Promise.reject(new Error('failed'))
	})
export default requests;